import React from 'react';

export default function No404() {
  return (
    <>
      <svg width="248" height="99" viewBox="0 0 248 99" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M64.477 63.3108V1.64088H40.4184L0 64.5414V80.2666H45.6426V97.3591H64.477V80.2666H75.7502V63.3108H64.477ZM45.6426 23.7928V63.3108H20.3467L45.6426 23.7928Z" fill="#31581C" />
        <path d="M123.477 99C146.161 99 158.947 85.4627 158.947 62.9005V36.0994C158.947 13.5373 146.161 0 123.477 0C100.931 0 88.1457 13.5373 88.1457 36.0994V62.9005C88.1457 85.4627 100.931 99 123.477 99ZM123.477 80.8135C113.579 80.8135 108.08 75.0704 108.08 62.9005V36.0994C108.08 23.9296 113.579 18.1865 123.477 18.1865C133.376 18.1865 138.875 23.9296 138.875 36.0994V62.9005C138.875 75.0704 133.376 80.8135 123.477 80.8135Z" fill="#31581C" />
        <path d="M236.727 63.3108V1.64088H212.668L172.25 64.5414V80.2666H217.892V97.3591H236.727V80.2666H248V63.3108H236.727ZM217.892 23.7928V63.3108H192.597L217.892 23.7928Z" fill="#31581C" />
      </svg>

    </>
  );
}



