import { createApi } from "@reduxjs/toolkit/query/react";
import type { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import axiosClient from "./axiosClient";
import type { AxiosRequestConfig, AxiosError } from "axios";


const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig["method"]
      data?: AxiosRequestConfig["data"]
      params?: AxiosRequestConfig["params"]
      headers?: AxiosRequestConfig["headers"]
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {

    try {
      const result = await axiosClient({
        url: baseUrl + url, 
        method, 
        data,
        params,
        headers
      })
      
      return { data: result.data };

    } catch (axiosError) {
      let err = axiosError as AxiosError;

      // Use this to handle 500 Server Errors
      if (err.response?.status === 500) {
        return {
          error: {
            status: err.response?.status,
            data: {
              message: "An error occured! Please try again later"
            },
          },
        }
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }




export const apiSlice = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({})
})

