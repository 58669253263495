import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { selectCurrentUser, updateCredentials } from "../../featuress/auth/authSlice";
import { useValidateUserQuery } from "../../featuress/auth/authApiSlice";
import { IRedirectUrl } from "../../models/authModels/authModels";


function RequireAuth({ url }: IRedirectUrl) {
    const location = useLocation();
    const dispatch = useDispatch();
    const redirectUrl = url ?? "/";

    const currentUser = useSelector(selectCurrentUser);
    
    const { data, error } = useValidateUserQuery(undefined, { refetchOnMountOrArgChange: true })

    useEffect(()=> {
        if (data) {
            let newUser = { ...currentUser, ...data?.data};
            dispatch(updateCredentials(newUser));
        }
        return () => {
            console.log("clean up");
        };
    }, [data, dispatch]);
   

    if(!currentUser || error){
       return <Navigate to={redirectUrl} state={{from: location}} replace/>
    }

    if(!currentUser?.phone_number_verified_at){
        if(location.pathname !== '/verify-phone-number'){
            return <Navigate to='/verify-phone-number' state={{from: location}} replace/>
        }
    }

    return <Outlet />;
}

export default RequireAuth;