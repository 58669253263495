import React from "react";
import { IButtonProps, ILoadingProps } from "./models/models"


const Button = ({ className, disabled, spinnerColor, children, ...restProps }: IButtonProps) => {

    return (
        <button className={className} disabled={disabled} {...restProps}>
            <Loading className={disabled ? "visible" : "invisible"} spinnerColor={spinnerColor!} />
            <span className={!disabled  ? "visible" : "invisible"}>{children}</span>
        </button>
    )

}

function Loading({ className, spinnerColor = "#fff" }: ILoadingProps) {
    return (
        <>
            <svg className={`${className} position-absolute top-50 start-50 translate-middle`} style={{ margin: "auto", background: "transparent", display: "block" }} width="27px" height="27px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" r="37" strokeWidth="9" stroke={spinnerColor} strokeDasharray="58.119464091411174 58.119464091411174" fill="none" strokeLinecap="round">
                    <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                </circle>
                <circle cx="50" cy="50" r="27" strokeWidth="9" stroke={spinnerColor} strokeDasharray="42.411500823462205 42.411500823462205" strokeDashoffset="42.411500823462205" fill="none" strokeLinecap="round">
                    <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>
                </circle>
            </svg>
        </>
    )
}


export default Button;

