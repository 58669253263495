import { createSlice } from "@reduxjs/toolkit";
import { ICurrentBalance } from "../../models/creditModel/creditModels";

let refetchBalance = JSON.parse(localStorage.getItem("fuel-credit-balance")!);

const userDashboardSlice = createSlice({
  name: "userDashboard",
  initialState: { balance: refetchBalance },
  reducers: {
    setBalance: (state, action) => {
      state.balance = action.payload === undefined ? 0 : action.payload;
      localStorage.setItem("fuel-credit-balance", JSON.stringify(state.balance));
    },
    addToBalance: (state, action) => {
      let newBalance = Number(state.balance) + Number(action.payload);
      localStorage.setItem("fuel-credit-balance", JSON.stringify(state.balance));
      state.balance = newBalance;
    },
    minusFromBalance: (state, action) => {
      let newBalance = Number(state.balance) - action.payload;
      localStorage.setItem("fuel-credit-balance", JSON.stringify(state.balance));
      state.balance = newBalance;
    },
  },
})


export const { setBalance, addToBalance, minusFromBalance } = userDashboardSlice.actions;

export default userDashboardSlice.reducer;

export const selectCurrentBalance = (state: ICurrentBalance) => state.userDashboard.balance;
