import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useValidateUserQuery } from "../../featuress/auth/authApiSlice";
import { IRedirectUrl } from "../../models/authModels/authModels";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../featuress/auth/authSlice";

function RedirectIfNotAuth({ url }: IRedirectUrl) {
    const location = useLocation();
   
    const redirectUrl = url ?? "/dashboard";
    const currentUser = useSelector(selectCurrentUser);
    
    const { error, isLoading } = useValidateUserQuery();

    if(error == null && currentUser != null) {       
        if(currentUser?.phone_number_verified_at === null){
            return <Navigate to='/verify-phone-number' state={{from: location}} replace/>
        }
        
        return <Navigate to={redirectUrl} state={{from: location}} replace/>
    }

    return (<Outlet />);
    
}

export default RedirectIfNotAuth;