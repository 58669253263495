//@ts-nocheck

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PinComponent from "../shared/PinComponent";
import { ReactComponent as VerifyPhone } from "../assets/svgs/verify_phone.svg";
import {useResendPhoneVerificationCodeMutation, useVerifyPhoneNumberMutation } from "../featuress/auth/authApiSlice";
import Button from "../shared/Button/Button";
import {useSelector } from "react-redux";
import useUpdateUser from "../hooks/useUpdateUser";
import { selectCurrentUser } from "../featuress/auth/authSlice";


const VerifyPhoneNumber = () => {

    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [statusMessage, setStatusMessage] = useState("");
    const [statusError, setStatusError] = useState("");
    const [resendError, setResendError] = useState("");
    const [disableResendOTP, setDisableResendOTP] = useState(false);
    const [disableEditPhone, setDisableEditPhone] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const navigate = useNavigate();

    const {getUser, error, isLoading} = useUpdateUser();
    const user = useSelector(selectCurrentUser);

    const [verifyPhoneNumber] = useVerifyPhoneNumberMutation();
    const [resendPhoneVerificationCode] = useResendPhoneVerificationCodeMutation();

    useEffect(()=>{
        if(user?.phone_number_verified_at){
            navigate("/dashboard");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const resendOTP = async () => {
        if (disableResendOTP || disableEditPhone) {
            setResendError("Wait for about 60secs before sending another OTP");
            setResendError("");
        }

        setStatusMessage("")
        setStatusError("")
        setDisableResendOTP(true);
        setDisableEditPhone(true);

        try {
            const resendToken = await resendPhoneVerificationCode().unwrap()
            setStatusMessage(`${resendToken?.message}`);
            setTimeout(() => {
                setDisableResendOTP(false);
                setDisableEditPhone(false);
            }, 1000);

        } catch (err: any) {

            if (!err.status) {
                setDisableResendOTP(false);
                setDisableEditPhone(false);
                let errText = err?.data ?? "Network failure. Please try again after something";
                setStatusError(`${errText}`);
                
            } else {
                setDisableResendOTP(false);
                setDisableEditPhone(false);
                let errText = err?.data?.message ?? "OTP resend failed. Try again later";
                setStatusError(`${errText}`);
            }

            
        }

    }


    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let joinOtp = otp.join("");
        setDisableBtn(true);

        try {

            await verifyPhoneNumber({ token: `${joinOtp}` }).unwrap()
            //setStatusMessage(`${verifyData?.message}`);
            getUser();
            setTimeout(() => {
              navigate("/dashboard");
            }, 1000);

        } catch (err: any) {
          // console.log("err : ", err);
            let errText = err?.data?.message ?? "Something went wrong. Retry."
            setStatusError(`${errText}`);
            setDisableBtn(false);
        }
    }

    const resendNotAllowed = () => {
        setStatusError("You are not allowed to send OTP yet until after 60secs");
        setTimeout(() => {
            setStatusError("");
        }, 300);
    }

    const handleBack = () => {
        window.localStorage.removeItem("FC_USER");
        window.localStorage.removeItem("FC_TOKEN");
        setTimeout(() => {
            navigate("/login");
        }, 500);
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="mx-auto col-12 col-md-10 col-lg-7 verify-phone-number-box position-relative"> 
                        <button 
                            type="button"
                            style={{ border: "none", backgroundColor: "transparent", top: "40px", right: "40px", color: "rgba(143, 146, 142, 1)" }}
                            className="position-absolute btn-close"
                            aria-label="Close"
                            onClick={handleBack}
                        >
                           
                        </button>
                        <div className="pt-5 verify-phone-number-inner-box">
                            <div className="verify-phone-number-title justify-content-center">
                                <VerifyPhone />
                                <h3 className="text-center dashboard-header-text mb-3">Verify your phone number</h3>
                            </div>
                            <p className="mt-2 text-center general-text">
                                A 6-digit One Time Password (OTP) has been sent to <span className="fw-bold">{user?.phone_number}.</span> Enter the OTP below to complete your account registration
                            </p>
                            <form
                                className="mt-5"
                                id="otpForm"
                                autoComplete="off"
                                onSubmit={handleSubmit}
                            >
                                {statusMessage && (
                                    <p className="general-text text-primary text-center mt-3">{statusMessage}</p>
                                )}
                                <PinComponent pin={otp} setPin={setOtp} inputType="" />

                                {statusError && (
                                    <p className="styled-form-error general-text text-danger text-center mt-3">{statusError}</p>
                                )}
                                {statusError && (
                                    <p className="styled-form-error general-text text-danger text-center mt-3">{error}</p>
                                )}
                                <div style={{ marginBottom: "10px" }}>
                                {resendError && (
                                    <p className="general-text text-primary text-center mt-3">{resendError}</p>
                                )}
                                </div>
                                <Button
                                    type="submit"
                                    className="btn btn-primary btn-progress mb-3 mb-md-4 mb-lg-5 mt-4"
                                    children="Submit"
                                    disabled={disableBtn || isLoading}
                                />
                            </form>
                            <div className="mt-5 mb-3 d-flex justify-content-end">
                                {/* <span id="editPhoneNumber" style={{cursor:"pointer"}} className={`px-2 me-0 general-text text-decoration-none text-primary ${disableEditPhone ? "text-muted" : "text-success"}`} onClick={() => disableEditPhone ? null : navigate("/edit-phone") }>Edit phone number</span> */}
                                <p id="resendOTP" className={`px-2 me-0 general-text text-decoration-none ${disableResendOTP ? "text-muted" : "text-primary"}`}
                                onClick={!disableResendOTP ? resendOTP : resendNotAllowed}
                                style={{ cursor: "pointer" }}
                                >
                                    Resend OTP
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );

}


export default VerifyPhoneNumber;
