import React from "react";

export default function Fallback() {
    return (
        <>
            <div className="d-flex" style={{ marginTop: "6rem", marginBottom: "6rem" }}>                
                <svg className="mx-auto" xmlns="http://www.w3.org/2000/svg"  style={{margin: "auto", background: "rgb(255, 255, 255)", display: "block", shapeRendering: "auto"}} width="103px" height="103px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="rotate(0 50 50)">
                        <rect x="47" y="25" rx="3" ry="3.36" width="6" height="12" fill="#4fb518">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.0869565217391304s" begin="-0.9963768115942028s" repeatCount="indefinite"></animate>
                        </rect>
                    </g><g transform="rotate(30 50 50)">
                        <rect x="47" y="25" rx="3" ry="3.36" width="6" height="12" fill="#4fb518">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.0869565217391304s" begin="-0.9057971014492753s" repeatCount="indefinite"></animate>
                        </rect>
                    </g><g transform="rotate(60 50 50)">
                        <rect x="47" y="25" rx="3" ry="3.36" width="6" height="12" fill="#4fb518">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.0869565217391304s" begin="-0.8152173913043478s" repeatCount="indefinite"></animate>
                        </rect>
                    </g><g transform="rotate(90 50 50)">
                        <rect x="47" y="25" rx="3" ry="3.36" width="6" height="12" fill="#4fb518">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.0869565217391304s" begin="-0.7246376811594202s" repeatCount="indefinite"></animate>
                        </rect>
                    </g><g transform="rotate(120 50 50)">
                        <rect x="47" y="25" rx="3" ry="3.36" width="6" height="12" fill="#4fb518">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.0869565217391304s" begin="-0.6340579710144927s" repeatCount="indefinite"></animate>
                        </rect>
                    </g><g transform="rotate(150 50 50)">
                        <rect x="47" y="25" rx="3" ry="3.36" width="6" height="12" fill="#4fb518">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.0869565217391304s" begin="-0.5434782608695652s" repeatCount="indefinite"></animate>
                        </rect>
                    </g><g transform="rotate(180 50 50)">
                        <rect x="47" y="25" rx="3" ry="3.36" width="6" height="12" fill="#4fb518">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.0869565217391304s" begin="-0.45289855072463764s" repeatCount="indefinite"></animate>
                        </rect>
                    </g><g transform="rotate(210 50 50)">
                        <rect x="47" y="25" rx="3" ry="3.36" width="6" height="12" fill="#4fb518">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.0869565217391304s" begin="-0.3623188405797101s" repeatCount="indefinite"></animate>
                        </rect>
                    </g><g transform="rotate(240 50 50)">
                        <rect x="47" y="25" rx="3" ry="3.36" width="6" height="12" fill="#4fb518">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.0869565217391304s" begin="-0.2717391304347826s" repeatCount="indefinite"></animate>
                        </rect>
                    </g><g transform="rotate(270 50 50)">
                        <rect x="47" y="25" rx="3" ry="3.36" width="6" height="12" fill="#4fb518">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.0869565217391304s" begin="-0.18115942028985504s" repeatCount="indefinite"></animate>
                        </rect>
                    </g><g transform="rotate(300 50 50)">
                        <rect x="47" y="25" rx="3" ry="3.36" width="6" height="12" fill="#4fb518">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.0869565217391304s" begin="-0.09057971014492752s" repeatCount="indefinite"></animate>
                        </rect>
                    </g><g transform="rotate(330 50 50)">
                        <rect x="47" y="25" rx="3" ry="3.36" width="6" height="12" fill="#4fb518">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1.0869565217391304s" begin="0s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    </svg>
            </div>

        </>
    )
}
