import React from "react";
import No404 from "../images/No404.js"
import WomanSitting404 from "../images/womanSitting404.js"
import { Link } from "react-router-dom";

export default function NotFound404() {
    return (
        <>
            <div className="container">
                <div className="row d-flex flex-column align-items-center" style={{marginTop:"4rem"}}>
                    <No404 />
                    <WomanSitting404 />
                    <h3 className="section-title text-center">Oops! Not Found</h3>
                    <Link to="/dashboard" className="d-flex text-decoration-none mt-3">
                        <button className="btn-alt-general mx-auto">Back to hompage</button>
                    </Link> 
                </div>
            </div>
        </>
    );
}
