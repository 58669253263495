// @ts-nocheck

import { createSlice } from "@reduxjs/toolkit";
import { ICurrentUser } from "../../models/authModels/authModels";

// "undefined" is not valid JSON
const isJsonValid = () => {
  try {
    const result = JSON.parse(localStorage.getItem("FC_TOKEN"));
    // console.log("Valid JSON");
    return result;
    
  } catch (err) {
    // 👇️ This runs
    // console.log('Error token: ', err);
    localStorage.removeItem("FC_USER");
    localStorage.removeItem("FC_TOKEN");
  }
}

const isUserValid = () => {
  try {
    const result = JSON.parse(localStorage.getItem("FC_USER")) || null;
    // console.log("Valid JSON");
    return result;
    
  } catch (err) {
    // 👇️ This runs
    // console.log('Error useer: ', err.message);
    localStorage.removeItem("FC_USER");
    localStorage.removeItem("FC_TOKEN");
  }
}

isJsonValid();
isUserValid();
let refetchUser = JSON.parse(localStorage.getItem("FC_USER")) || null;


const authSlice = createSlice({
  name: "auth",
  initialState: { user: refetchUser },
  reducers: {
    setCredentials: (state, action) => {
      state.user = action.payload.user;
      localStorage.setItem("FC_USER", JSON.stringify(state.user));
      localStorage.setItem("FC_TOKEN", JSON.stringify(action.payload.token));
    },
    deleteCredentials: (state, action) => {
      state.user = null;
      localStorage.removeItem("FC_USER");
      localStorage.removeItem("FC_TOKEN");
    },
    updateCredentials: (state, action) => {
      let newState = { ...state.user, ...action.payload };
      state.user = newState;
      localStorage.setItem("FC_USER", JSON.stringify(newState));

    }
  },
})


export const { setCredentials, deleteCredentials, updateCredentials } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: ICurrentUser) => state.auth.user;
