import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";
import "./App.scss";

// Auth component
import RequireAuth from "./components/RequireAuth/RequireAuth";
import RedirectIfNotAuth from "./components/RedirectIfNotAuth/RedirectIfNotAuth";
import VerifyPhoneNumber from "./components/VerifyPhoneNumber";
import Fallback from "./components/Fallback";
import NotFound404 from "./components/NotFound404";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";

// Pages
const Login = React.lazy(() => import("./pages/individual/Login/Login"));
const IndividualRegisterFormPage = React.lazy(() => import("./pages/individual/registerForm/IndividualRegisterFormPage"));
const IndividualLandingPage = React.lazy(() => import("./pages/individual/individualLandinPage/IndividualLandingPage"));
const IndividualDashboardHome = React.lazy(() => import("./pages/individual/home/IndividualDashboardHome"));
const IndividualDashboardWallet = React.lazy(() => import("./pages/individual/wallet/IndividualDashboardWallet"));
const IndividualDashboardCredit = React.lazy(() => import("./pages/individual/credit/IndividualDashboardCredit"));
const DashboardCreditRequest = React.lazy(() => import("./pages/individual/creditRequest/DashboardCreditRequest"));
const IndividualDashboardBeneficiaries = React.lazy(() => import("./pages/individual/beneficiaries/IndividualDashboardBeneficiaries"));
const IndividualDashboardAddBeneficiary = React.lazy(() => import("./pages/individual/addBeneciary/IndividualDashboardAddBeneficiary"));
const IndividualDashboardBeneficiary = React.lazy(() => import("./pages/individual/beneficiary/DashboardBeneficiary/IndividualDashboardBeneficiary"));
const IndividualDashboardFillingStations = React.lazy(() => import("./pages/individual/fillingStations/IndividualDashboardFillingStations"));
const CustomerSupportPage = React.lazy(() => import("./pages/individual/CustomerSupportPage"));
const IndividualDashboardProfile = React.lazy(() => import("./pages/individual/profile/IndividualDashboardProfile"));
const IndividualDashboardEditProfile = React.lazy(() => import("./pages/individual/editProfile/IndividualDashboardEditProfile"));

// Components
const IndividualDashboardApp = React.lazy(() => import("./components/individual/IndividualDashboardApp"));
const AboutUsPage = React.lazy(() => import("./components/AboutUs"));
const FAQPage = React.lazy(() => import("./components/FAQs"));
const PrivacyPolicy = React.lazy(() => import("./components/PrivacyPolicy"));
const TermsOfService = React.lazy(() => import("./components/TermsOfService"));
const AccountType = React.lazy(() => import("./components/AccountTypePage"));
const ForgotPasswordPhone = React.lazy(() => import("./components/ForgotPasswordPhone"));
const ForgotPasswordEmail = React.lazy(() => import("./components/ForgotPasswordEmail"));


const App = () => {

  return (
    <Provider store={store}>
      <>
        <Router>
          <ScrollToTop />
          <div>
            <Routes>
             
              <Route path="/" element={<Header />}>
                 {/* Guest Routes */}
                <Route index element={
                  <React.Suspense fallback={<Fallback />}>
                    <IndividualLandingPage />
                  </React.Suspense>
                } />
                <Route path="*" element={<NotFound404 />} />
                <Route path="privacy-policy" element={
                  <React.Suspense fallback={<Fallback />}>
                    <PrivacyPolicy />
                  </React.Suspense>
                }></Route>
                <Route path="terms-of-service" element={
                  <React.Suspense fallback={<Fallback />}>
                    <TermsOfService />
                  </React.Suspense>
                }></Route>
                <Route path="aboutus" element={
                  <React.Suspense fallback={<Fallback />}>
                    <AboutUsPage />
                  </React.Suspense>
                }></Route>
                <Route path="faq" element={
                  <React.Suspense fallback={<Fallback />}>
                    <FAQPage />
                  </React.Suspense>
                }></Route>
                <Route path="account-type" element={
                  <React.Suspense fallback={<Fallback />}>
                    <AccountType />
                  </React.Suspense>
                }></Route>

                {/* Guest Only Route */}
                <Route element={<RedirectIfNotAuth />}>
                  <Route path="login" element={
                    <React.Suspense fallback={<Fallback />}>
                      <Login />
                    </React.Suspense>
                  }></Route>
                    <Route path="individual-form" element={
                    <React.Suspense fallback={<Fallback />}>
                      <IndividualRegisterFormPage />
                    </React.Suspense>
                  }></Route>
                </Route>

              <Route element={<RequireAuth url="/login" />}>
                <Route path="verify-phone-number" element={
                  <VerifyPhoneNumber />
                }></Route>
              </Route>
                <Route path="forgot-password-phone" element={
                  <React.Suspense fallback={<Fallback />}>
                    <ForgotPasswordPhone />
                  </React.Suspense>
                }></Route>
                <Route path="forgot-password-email" element={
                  <React.Suspense fallback={<Fallback />}>
                    <ForgotPasswordEmail />
                  </React.Suspense>
                }></Route>

                </Route>


              {/* Individual Auth Routes */}
              <Route element={<RequireAuth url="/login" />}>
                  <Route path="/dashboard" element={
                    <React.Suspense fallback={<Fallback />}>
                        <IndividualDashboardApp />
                    </React.Suspense>
                  }>
                    <Route index element={
                      <React.Suspense fallback={<Fallback />}>
                        <IndividualDashboardHome />
                      </React.Suspense>
                    } />
                    <Route path="wallet" element={
                      <React.Suspense fallback={<Fallback />}>
                        <IndividualDashboardWallet />
                      </React.Suspense>
                    }></Route>
                    <Route path="credit" element={
                      <React.Suspense fallback={<Fallback />}>
                        <IndividualDashboardCredit />
                      </React.Suspense>
                    }></Route>
                    <Route path="credit/request" element={
                      <React.Suspense fallback={<Fallback />}>
                        <DashboardCreditRequest />
                      </React.Suspense>
                    }></Route>
                    <Route path="beneficiaries" element={
                      <React.Suspense fallback={<Fallback />}>
                        <IndividualDashboardBeneficiaries />
                      </React.Suspense>
                    }>
                    </Route>
                    <Route path="beneficiaries/add" element={
                      <React.Suspense fallback={<Fallback />}>
                        <IndividualDashboardAddBeneficiary />
                      </React.Suspense>
                    }>
                    </Route>
                    <Route path="beneficiaries/:id" element={
                      <React.Suspense fallback={<Fallback />}>
                        <IndividualDashboardBeneficiary />
                      </React.Suspense>
                    }>
                    </Route>
                    <Route path="profile" element={
                      <React.Suspense fallback={<Fallback />}>
                        <IndividualDashboardProfile />
                      </React.Suspense>
                    }>
                    </Route>
                    <Route path="profile/edit" element={
                      <React.Suspense fallback={<Fallback />}>
                        <IndividualDashboardEditProfile />
                      </React.Suspense>
                    }>
                    </Route>
                    <Route path="filling-stations" element={
                      <React.Suspense fallback={<Fallback />}>
                        <IndividualDashboardFillingStations />
                      </React.Suspense>
                    }></Route>
                    <Route path="customer-support" element={
                      <React.Suspense fallback={<Fallback />}>
                        <CustomerSupportPage />
                      </React.Suspense>
                    }></Route> 
                  </Route>
              </Route>

            </Routes>
          </div>
        </Router>
      </>

    </Provider>
  );

}

export default App;
