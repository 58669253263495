import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateCredentials } from "../featuress/auth/authSlice";
import { useLazyValidateUserQuery } from "../featuress/auth/authApiSlice";


type Result = {
    getUser: () => void,
    isLoading: boolean,
    error: any,
};

const useUpdateUser =  (callback?: Function): Result => {
    const dispatch = useDispatch();
    const [trigger, {data, isLoading, error}]= useLazyValidateUserQuery();
    
    

    useEffect(() => {
       if(data){
            dispatch(updateCredentials(data.data));
            if(callback){
                callback();
            }
            
       }

       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleUpdateUser = () => {
        trigger();
    };

      return {
        getUser: handleUpdateUser,
        error: error,
        isLoading: isLoading,
      };
  
}

export default useUpdateUser;