// @ts-nocheck

import { apiSlice } from "../../app/api/apiSlice";
import { 
  IValidateUser, 
  ILoginArgs, 
  ILoginRes, 
  ILogoutRes, 
  IRegisterArgs, 
  IRegisterRes, 
  IVerifyPhoneNumberRes
} from "../../models/authModels/authModels";



export const authApiSlice = apiSlice.injectEndpoints({
  endpoints(build) {
    return {
      login: build.mutation<ILoginRes, ILoginArgs>({
        query: (credentials) => ({
          url: `/api/user/login`,
          method: "POST",
          data: { ...credentials },
          params: ""
        })
      }),
      logout: build.mutation<ILogoutRes, void>({
        query: () => ({
          url: `/api/user/logout`,
          method: "POST",
          params: ""
        }),
      }),
      validateUser: build.query<IValidateUser, void>({
        query: () => ({
          url: `/api/user`,
          method: "GET",
          params: ""
        })
      }),
      register: build.mutation<IRegisterRes, IRegisterArgs>({
        query: ({ payload }) => ({
          url: `/api/user/register`,
          method: "POST",
          data: { ...payload },
          params: ""
        })
      }),
      verifyPhoneNumber: build.mutation<IVerifyPhoneNumberRes, any>({
        query: ({ token }) => ({
          url: `/api/verification/verify`,
          method: "POST",
          params: { token }
        })
      }),
      resendPhoneVerificationCode: build.mutation<IVerifyPhoneNumberRes, void>({
        query: () => ({
          url: `/api/verification/send`,
          method: "POST",
          params: ""
        })
      }),
      postRequestPasswordReset: build.mutation({
        query: ({ payload }) => ({
          url: `/api/user/password/request`,
          method: "POST",
          data: { ...payload },
          params: ""
        })
      }), 
      postResetUserPassword: build.mutation({
        query: ({ payload }) => ({
          url: `/api/user/password/reset`,
          method: "POST",
          data: { ...payload },
          params: ""
        })
      }),
    }
  }
})



export const {
  useLoginMutation,
  useLogoutMutation,
  useValidateUserQuery,
  useLazyValidateUserQuery,
  useRegisterMutation,
  useVerifyPhoneNumberMutation,
  useResendPhoneVerificationCodeMutation,
  usePostRequestPasswordResetMutation,
  usePostResetUserPasswordMutation,
} = authApiSlice;